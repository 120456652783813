.project-modal {
	margin: calc(var(--modal-padding) * 0.25) var(--modal-padding) 0 var(--modal-padding);
}

.project-modal-carousel {
	/* display: grid; */
	/* grid-template-columns: 5% 90% 5%; */
	align-items: center;
	/* border: 1px black solid; */
	margin-top: var(--modal-padding);
}

.project-modal-image {
	object-fit: scale-down;
	object-position: 50% 50%;
	max-height: 30vh;
	width: auto;
}

.project-modal-title {
	margin-top: 5vh;
	line-height: calc(15px + 2vmin);
}

.project-modal-title-name {
	font-family: var(--main-title-font);
	font-size: calc(30px + 2vmin);
	font-weight: bold;
	color: var(--main-orange);
}

.project-modal-title-date {
	font-family: var(--main-title-font);
	font-size: calc(10px + 2vmin);
	font-weight: lighter;
	color: var(--main-grey-dark);
}

.project-modal-description {
	font-family: var(--main-body-font);
	text-indent: 2em;
	margin-top: 1vh;
	font-size: calc(10px + 0.5vmin);
	font-weight: normal;
}

.project-modal-description a:link{
	font-family: var(--main-body-font);
	font-size: calc(9px + 0.5vmin);
	color: var(--main-grey-dark);
	text-decoration: none;
	font-weight: bold;
}
.project-modal-description a:visited {
	font-family: var(--main-body-font);
	color: var(--main-grey-dark);
}
.project-modal-description a:hover {
	font-family: var(--main-body-font);
	color: var(--main-grey-light);
	text-decoration: underline;
}
.project-modal-description a:active {
	font-family: var(--main-body-font);
	color: var(--main-grey-light);
	text-decoration: underline;
}


.project-modal-link .material-icons {
	font-size: calc(9px + 1vmin);
	position: relative;
	top: calc((9px + 1vmin)/4);
	margin-left: 3px;
}

.project-modal-link a:link {
	font-family: var(--main-body-font);
	font-size: calc(9px + 0.5vmin);
	color: var(--main-grey-dark);
	text-decoration: none;
}

.project-modal-link a:visited {
	color: var(--main-grey-dark);
}
.project-modal-link a:hover {
	color: var(--main-grey-light);
	text-decoration: underline;
}
.project-modal-link a:active {
	color: var(--main-grey-light);
	text-decoration: underline;
}


.project-modal-credits {
	font-family: var(--main-body-font);
	margin-top: 1vh;
}


.next-button {
	/* grid-area: nextButton; */
	font-size: 5vmin;
}

.prev-button {
	/* grid-area: prevButton; */
	font-size: 5vmin;
}

.credits {
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: 3fr 10fr;
	font-family: var(--main-body-font);
}

.credit-name {
	font-size: calc(5px + 1vmin);
	font-weight: bold;
}

.credit-name:link { color: #000000; text-decoration: underline; }
.credit-name:visited { color: #000000; text-decoration: underline; }
.credit-name:hover { color: #808080; text-decoration: underline; }
.credit-name:active { color: #000000; text-decoration: underline; }

.credit-contribution {
	font-size: calc(5px + 1vmin);
	font-weight: lighter;
}

.rodal-close-icon {
	position: fixed;
	--close-icon-size: calc(30px + 3vmin);
	font-size: var(--close-icon-size);
	bottom: var(--modal-padding);
	left: calc(50% - (var(--close-icon-size)/2));
	/* margin-top: var(--modal-padding); */
	/* margin-left: var(--modal-padding); */
}


/* Modified from ./rodal/lib/rodal.css */
/* -- container -- */
.rodal,
.rodal-mask {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.rodal {
	position: fixed;
}

/* -- mask -- */
.rodal-mask {
	position: absolute;
	background: rgba(0, 0, 0, .3);
}

/* -- dialog -- */
.rodal-dialog {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 101;
	/* padding: 3.5vmin; */
	background: hsl(0, 0%, 100%);
	border-radius: 0px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, .2);
}

.rodal-dialog:focus {
	outline: none;
}

/* -- close button -- */
.rodal-close {
	position: absolute;
	cursor: pointer;
	top: 16px;
	right: 16px;
	width: 16px;
	height: 16px;
}

.rodal-close:before,
.rodal-close:after {
	position: absolute;
	content: '';
	height: 2px;
	width: 100%;
	top: 50%;
	left: 0;
	margin-top: -1px;
	background: #999;
	border-radius: 100%;
	-webkit-transition: background .2s;
	transition: background .2s;
}

.rodal-close:before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.rodal-close:after {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.rodal-close:hover:before,
.rodal-close:hover:after {
	background: #333;
}

/* -- fade -- */
@-webkit-keyframes rodal-fade-enter {
	from {
		opacity: 0;
	}
}

@keyframes rodal-fade-enter {
	from {
		opacity: 0;
	}
}

.rodal-fade-enter {
	-webkit-animation: rodal-fade-enter both ease-in;
	animation: rodal-fade-enter both ease-in;
}

@-webkit-keyframes rodal-fade-leave {
	to {
		opacity: 0
	}
}

@keyframes rodal-fade-leave {
	to {
		opacity: 0
	}
}

.rodal-fade-leave {
	-webkit-animation: rodal-fade-leave both ease-out;
	animation: rodal-fade-leave both ease-out;
}

/* -- zoom -- */
@-webkit-keyframes rodal-zoom-enter {
	from {
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}
}

@keyframes rodal-zoom-enter {
	from {
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}
}

.rodal-zoom-enter {
	-webkit-animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
	animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-zoom-leave {
	to {
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}
}

@keyframes rodal-zoom-leave {
	to {
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}
}

.rodal-zoom-leave {
	-webkit-animation: rodal-zoom-leave both;
	animation: rodal-zoom-leave both;
}

/* -- slideDown -- */
@-webkit-keyframes rodal-slideDown-enter {
	from {
		-webkit-transform: translate3d(0, -100px, 0);
		transform: translate3d(0, -100px, 0);
	}
}

@keyframes rodal-slideDown-enter {
	from {
		-webkit-transform: translate3d(0, -100px, 0);
		transform: translate3d(0, -100px, 0);
	}
}

.rodal-slideDown-enter {
	-webkit-animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
	animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideDown-leave {
	to {
		-webkit-transform: translate3d(0, -100px, 0);
		transform: translate3d(0, -100px, 0);
	}
}

@keyframes rodal-slideDown-leave {
	to {
		-webkit-transform: translate3d(0, -100px, 0);
		transform: translate3d(0, -100px, 0);
	}
}

.rodal-slideDown-leave {
	-webkit-animation: rodal-slideDown-leave both;
	animation: rodal-slideDown-leave both;
}

/* -- slideLeft -- */
@-webkit-keyframes rodal-slideLeft-enter {
	from {
		-webkit-transform: translate3d(-150px, 0, 0);
		transform: translate3d(-150px, 0, 0);
	}
}

@keyframes rodal-slideLeft-enter {
	from {
		-webkit-transform: translate3d(-150px, 0, 0);
		transform: translate3d(-150px, 0, 0);
	}
}

.rodal-slideLeft-enter {
	-webkit-animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
	animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideLeft-leave {
	to {
		-webkit-transform: translate3d(-150px, 0, 0);
		transform: translate3d(-150px, 0, 0);
	}
}

@keyframes rodal-slideLeft-leave {
	to {
		-webkit-transform: translate3d(-150px, 0, 0);
		transform: translate3d(-150px, 0, 0);
	}
}

.rodal-slideLeft-leave {
	-webkit-animation: rodal-slideLeft-leave both;
	animation: rodal-slideLeft-leave both;
}

/* -- slideRight -- */
@-webkit-keyframes rodal-slideRight-enter {
	from {
		-webkit-transform: translate3d(150px, 0, 0);
		transform: translate3d(150px, 0, 0);
	}
}

@keyframes rodal-slideRight-enter {
	from {
		-webkit-transform: translate3d(150px, 0, 0);
		transform: translate3d(150px, 0, 0);
	}
}

.rodal-slideRight-enter {
	-webkit-animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
	animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideRight-leave {
	to {
		-webkit-transform: translate3d(150px, 0, 0);
		transform: translate3d(150px, 0, 0);
	}
}

@keyframes rodal-slideRight-leave {
	to {
		-webkit-transform: translate3d(150px, 0, 0);
		transform: translate3d(150px, 0, 0);
	}
}

.rodal-slideRight-leave {
	-webkit-animation: rodal-slideRight-leave both;
	animation: rodal-slideRight-leave both;
}

/* -- slideUp -- */
@-webkit-keyframes rodal-slideUp-enter {
	from {
		-webkit-transform: translate3d(0, 100px, 0);
		transform: translate3d(0, 100px, 0);
	}
}

@keyframes rodal-slideUp-enter {
	from {
		-webkit-transform: translate3d(0, 100px, 0);
		transform: translate3d(0, 100px, 0);
	}
}

.rodal-slideUp-enter {
	-webkit-animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
	animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideUp-leave {
	to {
		-webkit-transform: translate3d(0, 100px, 0);
		transform: translate3d(0, 100px, 0);
	}
}

@keyframes rodal-slideUp-leave {
	to {
		-webkit-transform: translate3d(0, 100px, 0);
		transform: translate3d(0, 100px, 0);
	}
}

.rodal-slideUp-leave {
	-webkit-animation: rodal-slideUp-leave both;
	animation: rodal-slideUp-leave both;
}

/* -- flip -- */
@-webkit-keyframes rodal-flip-enter {
	from {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
	}
	70% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
	}
	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}

@keyframes rodal-flip-enter {
	from {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
	}
	70% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
	}
	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}

.rodal-flip-enter {
	-webkit-animation: rodal-flip-enter both ease-in;
	animation: rodal-flip-enter both ease-in;
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
}

@-webkit-keyframes rodal-flip-leave {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
	30% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
	}
	to {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
	}
}

@keyframes rodal-flip-leave {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
	30% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
	}
	to {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
	}
}

.rodal-flip-leave {
	-webkit-animation: rodal-flip-leave both;
	animation: rodal-flip-leave both;
	-webkit-backface-visibility: visible !important;
	backface-visibility: visible !important;
}

/* -- rotate -- */
@-webkit-keyframes rodal-rotate-enter {
	from {
		-webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(.3, .3, .3);
		transform: rotate3d(0, 0, 1, -180deg) scale3d(.3, .3, .3);
	}
}

@keyframes rodal-rotate-enter {
	from {
		-webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(.3, .3, .3);
		transform: rotate3d(0, 0, 1, -180deg) scale3d(.3, .3, .3);
	}
}

.rodal-rotate-enter {
	-webkit-animation: rodal-rotate-enter both;
	animation: rodal-rotate-enter both;
	-webkit-transform-origin: center;
	transform-origin: center;
}

@-webkit-keyframes rodal-rotate-leave {
	to {
		-webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(.3, .3, .3);
		transform: rotate3d(0, 0, 1, 180deg) scale3d(.3, .3, .3);
	}
}

@keyframes rodal-rotate-leave {
	to {
		-webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(.3, .3, .3);
		transform: rotate3d(0, 0, 1, 180deg) scale3d(.3, .3, .3);
	}
}

.rodal-rotate-leave {
	-webkit-animation: rodal-rotate-leave both;
	animation: rodal-rotate-leave both;
	-webkit-transform-origin: center;
	transform-origin: center;
}

/* -- door -- */
@-webkit-keyframes rodal-door-enter {
	from {
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
	}
}

@keyframes rodal-door-enter {
	from {
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
	}
}

.rodal-door-enter {
	-webkit-animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
	animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-door-leave {
	60% {
		-webkit-transform: scale3d(.01, 1, 1);
		transform: scale3d(.01, 1, 1);
	}
	to {
		-webkit-transform: scale3d(0, 1, .1);
		transform: scale3d(0, 1, .1);
	}
}

@keyframes rodal-door-leave {
	60% {
		-webkit-transform: scale3d(.01, 1, 1);
		transform: scale3d(.01, 1, 1);
	}
	to {
		-webkit-transform: scale3d(0, 1, .1);
		transform: scale3d(0, 1, .1);
	}
}

.rodal-door-leave {
	-webkit-animation: rodal-door-leave both;
	animation: rodal-door-leave both;
}