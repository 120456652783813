:root {
	--project-grid-padding: 10vmin;
	--modal-padding: 5.5vmin;

	--main-orange: #F7931E;
	--main-orange-light: rgb(238, 166, 115);
	--main-grey-dark: #1F1F1F;
	--main-grey-light: #C9C9C9;

	--main-title-font: 'Raleway';
	--main-body-font: 'Roboto';
}

body {
	overflow: hidden;
	/* THIS SHOULD BE CHANGED TO 'auto' IN src/App.js handleLoad() */
}

html {
	scroll-behavior: smooth;
}

.material-icons {
	user-select: none;
}