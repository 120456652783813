/* https://css-tricks.com/creating-sliding-effects-using-sticky-positioning/ */

#toolbar {
	--toolbar-padding: 10vmin;
	--sort-dropdown-width: 18vw;

	z-index: 10;
	position: -webkit-sticky; /* Required for Safari */
	position: sticky;
	top: 0;
	height: 5vh;
	/* border: 1px red solid; */
	padding-left: var(--toolbar-padding);

	background: #151515;
}

#search-bar {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 35vw;
	height: 50%;

	outline: none;
	-webkit-transition: border 0.4s ease-in-out;
	transition: border 0.4s ease-in-out;
	border: 2px solid var(--main-grey-light);
	border-radius: 4px;

	background-image: url('./images/icons/search_black_18dp.png');
	background-repeat: no-repeat;
	padding-left: 35px;
	background-size: contain;
	background-position: 5px 50%;

	font-family: var(--main-body-font);
	font-size: 75%;
}

#search-bar:focus {
	border: 2px solid var(--main-orange);
}

#search-bar:hover {
	border: 2px solid var(--main-orange-light);
}

#sort-dropdown {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: var(--toolbar-padding);
	width: var(--sort-dropdown-width);
	height: 60%;

	outline: none;
	-webkit-transition: border 0.4s ease-in-out;
	transition: border 0.4s ease-in-out;
	border: 2px solid var(--main-grey-light);
	border-radius: 4px;

	font-family: var(--main-body-font);
	font-size: 75%;
}

#sort-dropdown:hover {
	border: 2px solid var(--main-orange);
}

#sort-label {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: calc(var(--toolbar-padding) + var(--sort-dropdown-width) + 7px);
	/* width: 20vw; */
	/* height: 50%; */
	color: var(--main-grey-light);
}