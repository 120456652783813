#footer {
	--footer-icon-size: 7.5vmin;

	background-color: var(--main-grey-dark);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.title {
	margin-left: 15vmin;
	margin-right: 15vmin;

	font-family: var(--main-title-font);
	font-size: calc(20px + 3vmin);
	font-weight: bold;
	color: var(--main-orange);
}

.description {
	margin-left: 15vmin;
	margin-right: 15vmin;

	font-family: var(--main-body-font);
	font-size: calc(10px + 1.5vmin);
	font-weight: lighter;
	color: var(--main-grey-light);
}

a:link { color: #ffffff; }
a:visited { color: #ffffff; }
a:hover { color: #ffffff; }
a:active { color: #ffffff; }

.footer-icon {
	width: var(--footer-icon-size);
	height: var(--footer-icon-size);
	margin: 0.5vmin;
	
	font-size: var(--footer-icon-size);
}

.icons-row {
	margin-left: 15vmin;
	margin-right: 15vmin;
}