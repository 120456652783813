.project-thumbnail {
	height: 250px;
	/* max-width: calc((100vw - (6 * 5vmin))/5); */
	/* border-radius: 20px; */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	font-family: var(--main-title-font);
}

.project-thumbnail .project-thumbnail-title-background {
	visibility: visible;
	width: 100%;
	height: 100%;
	background-color: #00000085;

	opacity: 0;
	transition: opacity 0.5s ease-in-out;

	position: relative;
}

.project-thumbnail:hover .project-thumbnail-title-background {
	opacity: 1;
}

.project-thumbnail-title {
	user-select: none;
	text-align: center;
	font-size: calc(15px + 1.5vmin);
	font-weight: bold;
	color: white;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}