.projects {
	/* width : 100vw;
	height: 100vh; */
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	/* grid-template-rows: repeat(auto-fit, minmax(250px, 1fr)); */
	grid-gap: 5vmin;

	padding: var(--project-grid-padding);
}

.category-title {
	font-family: var(--main-title-font);
	font-size: calc(20px + 4.5vmin);
	font-weight: bold;
	color: var(--main-orange);
	line-height: 0;
	

	padding-left: var(--project-grid-padding);
}

#content-placeholder {
	padding-top: var(--project-grid-padding);
}

#projects-grid {
	background: #ffffff;
}