#nav-bar {
	min-height: 5vh;
	background-color: var(--main-grey-dark);
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	justify-items: center;
	align-items: center;

	font-family: var(--main-title-font);
	color: var(--main-orange);
}

#banner::before {
	z-index: -1;
	content: "";
	position: absolute;
	top: 5vh; left: 0;
	width: 100%; height: 100%;
	padding: 0;
	background-image: url('./images/banner.png');
	background-position: center center;
	background-size: cover;
	filter: grayscale(75%) brightness(0.10);
}

#banner {
	/* background-color: var(--main-grey-dark); */
	/* background-image: url(https://i.imgur.com/y4PSpqK.png); */
	/* background-position: center center; */
	/* background-size: cover; */
	min-height: 95vh;
}

#description {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin-left: var(--project-grid-padding);
	margin-right: var(--project-grid-padding);
}

#title {
	font-family: var(--main-title-font);
	font-size: calc(20px + 2vmin);
	font-weight: bold;
	color: var(--main-orange);
}

#summary {
	text-indent: 5vw;
	font-family: var(--main-body-font);
	font-size: calc(10px + 2vmin);
	font-weight: lighter;
	color: var(--main-grey-light);
}

#scroll-indicator {
	user-select: none;
	position: absolute;
	left: calc(50% - 5vh);
	bottom: 5%;

	font-size: 10vh;
	color: var(--main-orange);
}

.name-bold {
	font-size: calc(16px + 2vmin);
	color: var(--main-orange);
}

.hidden {
	/* padding-top: 30%; */
	opacity: 0;
}

.shown {
	/* padding-top: 0%; */
	opacity: 1;

	transition: opacity 1.5s ease-in-out;
	/* transition: padding-top 1.5s ease-in-out; */
}
